import React, { Component } from "react"
import { Link } from 'gatsby';
import "../assets/fonts/font.css"
import "../assets/css/faq.css"
import Footer from '../components/footer';
import NewsLatter from '../components/newsletter';

import lineImage from "../images/line2.png"
import Layout from "../components/layout"
import handIcon from "../images/hand.svg"
import chatbottom from "../images/chat-bottom-icon.svg"
import chatProfile from "../images/chat-profile.png"
import closeChat from "../images/close-icon.svg"
import SEO from "../components/seo"


class LegalStuff extends Component {

  render() {
    return (
      <Layout rest={this.props} layoutType="main">
        <SEO title="Servicecontract.com - Terms of Sale" keywords={[`gatsby`, `application`, `react`]} />
        <div className="chat-content" style={{ display: 'none' }}>
          <div className="chat-content-circle">
            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="26.496" viewBox="0 0 30 26.496">
              <g id="Group_157" data-name="Group 157" transform="translate(-309.85 -157.354)">
                <g id="Group_27" data-name="Group 27" transform="translate(309.85 157.354)">
                  <path id="Path_73" data-name="Path 73" d="M309.85,170.605c0,7.3,6.733,13.245,15,13.245a16.468,16.468,0,0,0,7.738-1.9,8.731,8.731,0,0,0,4.123.967,10.089,10.089,0,0,0,1.427-.1,1.14,1.14,0,0,0,.46-2.089,4.986,4.986,0,0,1-1.8-2.132,12.3,12.3,0,0,0,3.045-8c0-7.3-6.733-13.245-15-13.245S309.85,163.3,309.85,170.605Zm1.5,0c0-6.476,6.058-11.744,13.5-11.744s13.5,5.269,13.5,11.744a10.721,10.721,0,0,1-2.971,7.345.756.756,0,0,0-.153.772,6.667,6.667,0,0,0,1.795,2.7,7.254,7.254,0,0,1-4.044-.968.717.717,0,0,0-.386-.1.745.745,0,0,0-.373.1,14.929,14.929,0,0,1-7.37,1.911C317.409,182.349,311.351,177.081,311.351,170.605Z" transform="translate(-309.85 -157.354)" fill="#fff" />
                  <circle id="Ellipse_1" data-name="Ellipse 1" cx="1.924" cy="1.924" r="1.924" transform="translate(13.085 11.327)" fill="#fff" />
                  <circle id="Ellipse_2" data-name="Ellipse 2" cx="1.924" cy="1.924" r="1.924" transform="translate(17.943 11.327)" fill="#fff" />
                  <circle id="Ellipse_3" data-name="Ellipse 3" cx="1.924" cy="1.924" r="1.924" transform="translate(8.227 11.327)" fill="#fff" />
                </g>
              </g>
            </svg>
          </div>
          <div className="chat-box">

            <div className="chatbox-inner">
              <div className="chat-profile">
                <img className="" src={chatProfile} alt="" />
              </div>
              <span className="close-chat"><img className="" src={closeChat} alt="" /></span>
              <p> <img className="hand-icon" src={handIcon} alt="" /> <span>Hi, We're here to help.</span></p>
            </div>
            <img className="chat-bottom" src={chatbottom} alt="" />
          </div>

        </div>

        <section className="faq-page-container terms-container">
          <div className="faq-tab-container">
            

          <h2 className="tab-title">Terms and Conditions of Sale</h2>
          
              <div className="tab-content">
              <p className="revdt">Rev. 10-2019</p>
                  <p><strong>{/*( Terms and Conditions for the Online Sale of Goods and Services) */}
                    A few things our attorneys want to make sure you know!
                  </strong></p>
                  <p>THIS DOCUMENT CONTAINS VERY IMPORTANT INFORMATION REGARDING YOUR RIGHTS AND OBLIGATIONS, AS WELL AS CONDITIONS, LIMITATIONS, AND EXCLUSIONS THAT MIGHT APPLY TO YOU.  PLEASE READ IT CAREFULLY.  </p>
                  <p>THESE TERMS REQUIRE THE USE OF ARBITRATION ON AN INDIVIDUAL BASIS TO RESOLVE DISPUTES, RATHER THAN JURY TRIALS OR CLASS ACTIONS.  </p>
                  <p>BY PLACING AN ORDER FOR PRODUCTS OR SERVICES FROM THIS SITE, YOU AFFIRM THAT YOU ARE OF LEGAL AGE TO ENTER INTO THIS AGREEMENT, AND YOU ACCEPT AND ARE BOUND BY THESE TERMS AND CONDITIONS. YOU AFFIRM THAT IF YOU PLACE AN ORDER ON BEHALF OF AN ORGANIZATION OR COMPANY, YOU HAVE THE LEGAL AUTHORITY TO BIND ANY SUCH ORGANIZATION OR COMPANY TO THESE TERMS AND CONDITIONS.</p>
                  <p>YOU MAY NOT ORDER OR OBTAIN PRODUCTS OR SERVICES FROM THIS SITE IF YOU (A) DO NOT AGREE TO THESE TERMS, (B) ARE NOT THE OLDER OF (i) AT LEAST 16 YEARS OF AGE OR (ii) LEGAL AGE TO FORM A BINDING CONTRACT WITH SERVICECONTRACT.COM, OR (C) ARE PROHIBITED FROM ACCESSING OR USING THIS SITE OR ANY OF THIS SITE'S CONTENTS, GOODS OR SERVICES BY APPLICABLE LAW.</p>
                  <p>These terms and conditions (these &ldquo;Terms&rdquo;) apply to the purchase and sale of products and services through servicecontract.com. These Terms are subject to change by servicecontract.com (referred to as &ldquo;us,&rdquo; &ldquo;we,&rdquo; or &ldquo;our&rdquo; as the context may require) without prior written notice at any time, in our sole discretion. Any changes to the Terms will be in effect as of the revision date referenced on the site. You should review these Terms prior to purchasing any product or services that are available through this site. Your continued use of this site after the revision date will constitute your acceptance of and agreement to such changes.</p>
                  <p>These Terms are an integral part of the site Terms of Use that apply generally to the use of our site. You should also carefully review our Privacy Policy / Notice before placing an order for products or services through this site.</p>
                  <p><strong>Order Acceptance and Cancellation</strong></p>
                  <p>You agree that your order is an offer to buy, under these Terms, all products and services listed in your order. All orders must be accepted by us or we will not be obligated to sell the products or services to you. We may choose not to accept orders in our sole discretion, even after we send you a confirmation email with your order number and details of the items you have ordered.</p>
                  <p><strong>Prices and Payment Terms</strong></p>
                  <p>Prices posted on this site may be different than prices offered by us at on other websites. All prices, discounts, and promotions posted on this site are subject to change without notice. The price charged for a product or service will be the price in effect at the time the order is placed and will be set out in your order confirmation email and/or in your account profile. Price increases will only apply to orders placed after such changes. Posted prices do not include taxes or charges for shipping and handling. All such taxes and charges will be added to your order total, and will be itemized in your shopping cart and in your order confirmation email and/or your account profile. We strive to display accurate price information, however we may, on occasion, make inadvertent typographical errors, inaccuracies or omissions related to pricing and availability. We reserve the right to correct any errors, inaccuracies, or omissions at any time and to cancel any orders arising from such occurrences.</p>
                  <p>We may offer from time to time promotions on the site that may affect pricing and that are governed by terms and conditions separate from these Terms. If there is a conflict between the terms for a promotion and these Terms, the promotion terms will govern.</p>
                  <p>Terms of payment are within our sole discretion and, unless otherwise agreed by us in writing, payment must be received by us before our acceptance of an order. We accept certain credit cards for all purchases. You represent and warrant that (i) the credit card information you supply to us is true, correct and complete, (ii) you are duly authorized to use such credit card for the purchase, (iii) charges incurred by you will be honored by your credit card company, and (iv) you will pay charges incurred by you at the posted prices, including shipping and handling charges and all applicable taxes, if any, regardless of the amount quoted on the Site at the time of your order.</p>
                  <p><strong>Delivery of Service Contract</strong></p>
                  <p>Upon acceptance of your order, you will receive an email confirmation with instructions on how to log into your account and download a copy of the service contract you purchased. You will pay all taxes and administrative charges specified during the ordering process. We are not liable for any delays in processing your order or confirming acceptance.</p>
                  <p><strong>Cancellations and Refunds</strong></p>
                  <p>Cancellations and refunds are only processed in accordance with the terms and conditions set forth in the service contract you purchased. Please see the contract for details on how to request cancellation and refund. You are responsible for any cancellation and other administration fees resulting from your request.</p>
                  <p><strong>Goods Not for Resale or Export</strong></p>
                  <p>You represent and warrant that you are buying products or services from the site for your own personal or household use only, and not for resale or export. You further represent and warrant that all purchases are intended for final delivery to locations within the US.</p>
                  <p><strong>No Warranties and Disclaimers</strong></p>
                  <p>We do not provide or administer of the products or services offered on our site. The availability of products or services through our site does not indicate an affiliation with or endorsement of any product, contract, service, or provider or administrator. Accordingly, we do not provide any warranties with respect to the products, contracts, or services offered on our site.</p>
                  <p>IN ADDITION TO THOSE DISCLAIMERS ON WARRANTIES AND LIMITATIONS ON LIABILITY SET FORTH IN OUR TERMS OF USE WHICH ARE INCORPORATED HEREIN BY REFERENCE, PLEASE BE ADVISED THAT ALL PRODUCTS AND SERVICES OFFERED ON THIS SITE ARE PROVIDED &ldquo;AS IS&rdquo; AND &ldquo;AS AVAILABLE&rdquo; WITHOUT ANY WARRANTY WHATSOEVER, INCLUDING, WITHOUT LIMITATION, ANY (A) WARRANTY OF MERCHANTABILITY; (B) WARRANTY OF FITNESS FOR A PARTICULAR PURPOSE; OR (C) WARRANTY AGAINST INFRINGEMENT OF INTELLECTUAL PROPERTY RIGHTS OF A THIRD PARTY; WHETHER EXPRESS OR IMPLIED BY LAW, COURSE OF DEALING, COURSE OF PERFORMANCE, USAGE OF TRADE, OR OTHERWISE.</p>
                  <p>SOME JURISDICTIONS LIMIT OR DO NOT ALLOW THE DISCLAIMER OF IMPLIED OR OTHER WARRANTIES SO THE ABOVE DISCLAIMER MAY NOT APPLY TO YOU.</p>
                  <p>YOU AFFIRM THAT WE SHALL NOT BE LIABLE, UNDER ANY CIRCUMSTANCES, FOR ANY BREACH OF WARRANTY CLAIMS OR FOR ANY DAMAGES ARISING OUT OF THE PROVIDER OR ADMINISTRATOR&rsquo;S FAILURE TO HONOR ITS OBLIGATIONS TO YOU.</p>
                  <p><strong>Limitation of Liability</strong></p>
                  <p>IN ADDITION TO THE DISCLAIMERS OF WARRANTIES AND LIMITATIONS ON LIABILITY SET FORTH IN OUR TERMS OF USE WHICH ARE INCORPROATED HEREIN BY REFERENCE, IN NO EVENT SHALL WE BE LIABLE TO YOU OR ANY THIRD PARTY FOR CONSEQUENTIAL, INDIRECT, INCIDENTAL, SPECIAL, EXEMPLARY, PUNITIVE OR ENHANCED DAMAGES[, LOST PROFITS OR REVENUES OR DIMINUTION IN VALUE], ARISING OUT OF, OR RELATING TO, AND/OR IN CONNECTION WITH ANY BREACH OF THESE TERMS, REGARDLESS OF (A) WHETHER SUCH DAMAGES WERE FORESEEABLE, (B) WHETHER OR NOT WE WERE ADVISED OF THE POSSIBILITY OF SUCH DAMAGES AND (C) THE LEGAL OR EQUITABLE THEORY (CONTRACT, TORT OR OTHERWISE) UPON WHICH THE CLAIM IS BASED.</p>
                  <p>OUR SOLE AND ENTIRE MAXIMUM LIABILITY, FOR ANY REASON, AND YOUR SOLE AND EXCLUSIVE REMEDY FOR ANY CAUSE WHATSOEVER, SHALL BE LIMITED TO THE ACTUAL AMOUNT PAID BY YOU FOR THE PRODUCTS AND SERVICES YOU HAVE ORDERED THROUGH OUR SITE.</p>
                  <p>The limitation of liability set forth above shall : (i) only apply to the extent permitted by law.</p>
                  <p><strong>Privacy</strong></p>
                  <p>We respect your privacy and are committed to protecting it. Our {/* Privacy Policy / Notice */} 
                  <Link to="/privacy"> Privacy Policy </Link> 
                   governs the processing of all personal data collected from you in connection with your purchase of products or services through the Site.</p>
                  <p><strong>Force Majeure</strong></p>
                  <p>We will not be liable or responsible to you, nor be deemed to have defaulted or breached these Terms, for any failure or delay in our performance under these Terms when and to the extent such failure or delay is caused by or results from acts or circumstances beyond our reasonable control, including, without limitation, acts of God, flood, fire, earthquake, explosion, governmental actions, war, invasion or hostilities (whether war is declared or not), terrorist threats or acts, riot or other civil unrest, national emergency, revolution, insurrection, epidemic, lockouts, strikes or other labor disputes (whether or not relating to our workforce), or restraints or delays affecting carriers or inability or delay in obtaining supplies of adequate or suitable materials, materials or telecommunication breakdown or power outage.</p>
                  <p><strong>Governing Law and Jurisdiction</strong></p>
                  <p>This Site is operated from the US. All matters arising out of or relating to these Terms are governed by and construed in accordance with the internal laws of the State of New Jersey without giving effect to any choice or conflict of law provision or rule (whether of the State of New Jersey or any other jurisdiction) that would cause the application of the laws of any jurisdiction other than those of the State of New Jersey.</p>
                  <p><strong>Dispute Resolution and Binding Arbitration</strong></p>
                  <p>We ask that you contact one of our customer service representatives regarding any questions, concerns, or disputes you may have so that we may resolve them as soon as possible. If we are unable to satisfy your concerns, you agree that you will resolve any dispute, claim, or controversy you have with servicecontract.com including but not limited to those arising out of or relating to these Terms of Sale, the services provided, and the service contracts made available to you through our site through binding arbitration or in small claims court in states where arbitration is not permitted to be required. If you seek arbitration or to file a claim in small claims court, you must first send notice of your dispute to servicecontract.com with a description of the nature and basis for the dispute and the specific relief sought at least 30 days prior to taking such action. Please end all notices to servicecontract.com, legal@servicecontract.com. Arbitration will be governed by the commercial arbitration rules of the American Arbitration Association. The rules and forms relating to the arbitration are available at www.adr.org or you an contact the AA by calling 1800-778-7879. The arbitrator&rsquo;s decision shall be final and binding on all parties except for judicial review as permitted by law, and review and enforcement of injunctive relief wherein the court shall not be bound by the legal conclusions of the arbitrator. The Federal Arbitration Act and federal arbitration law apply to these Terms of Sale.</p>
                  <p><strong>YOU AGREE THAT YOU MAY ONLY BRING CLAIMS AGAINST SERVICECONTRACT.COM IN YOUR INDIVIDUAL CAPACITY AND NOT AS A PLAINTIFF OR MEMBER OF A CLASS IN ANY PURPORTED CLASS OR REPRESENTATIVE PROCEEDING.</strong> In addition, unless consented to by serviecontract.com, more than one individual&rsquo;s claims may not be consolidated at arbitration or in small claims court.</p>
                  <p>YOU AND SERVICECONTRACT.COM ARE AGREEING TO GIVE UP ANY RIGHTS TO LITIGATE CLAIMS IN A COURT OR BEFORE A JURY, OR TO PARTICIPATE IN A CLASS ACTION OR REPRESENTATIVE ACTION WITH RESPECT TO A CLAIM. OTHER RIGHTS THAT YOU WOULD HAVE IF YOU WENT TO COURT MAY ALSO BE UNAVAILABLE OR MAY BE LIMITED IN ARBITRATION.</p>
                  <p>ANY CLAIM, DISPUTE OR CONTROVERSY (WHETHER IN CONTRACT, TORT OR OTHERWISE, WHETHER PRE-EXISTING, PRESENT OR FUTURE, AND INCLUDING STATUTORY, CONSUMER PROTECTION, COMMON LAW, INTENTIONAL TORT, INJUNCTIVE AND EQUITABLE CLAIMS) BETWEEN YOU AND US ARISING FROM OR RELATING IN ANY WAY TO YOUR PURCHASE OF PRODUCTS OR SERVICES THROUGH THE SITE, WILL BE RESOLVED EXCLUSIVELY AND FINALLY BY BINDING ARBITRATION.</p>
                  <p>The arbitration will be administered by the American Arbitration Association ("AAA") in accordance with the Consumer Arbitration Rules (the "AAA Rules") then in effect, except as modified by this section. (The AAA Rules are available at www.adr.org/arb_med or by calling the AAA at 1800-778-7879.) The Federal Arbitration Act will govern the interpretation and enforcement of this section.</p>
                  <p>The arbitrator will have exclusive authority to resolve any dispute relating to arbitrability and/or enforceability of this arbitration provision, including any unconscionability challenge or any other challenge that the arbitration provision or the agreement is void, voidable, or otherwise invalid. The arbitrator will be empowered to grant whatever relief would be available in court under law or in equity. Any award of the arbitrator(s) will be final and binding on each of the parties, and may be entered as a judgment in any court of competent jurisdiction.</p>
                  <p>We will be responsible for paying any individual consumer's arbitration fees/If you prevail on any claim that affords the prevailing party attorneys' fees, the arbitrator may award reasonable fees to you under the standards for fee shifting provided by law.</p>
                  <p>You may elect to pursue your claim in small-claims court rather than arbitration if you provide us with written notice of your intention do so within 60 days of your purchase. The arbitration or small-claims court proceeding will be limited solely to your individual dispute or controversy.</p>
                  <p>You agree to an arbitration on an individual basis. In any dispute, NEITHER YOU NOR SERVICECONTRACT.COM WILL BE ENTITLED TO JOIN OR CONSOLIDATE CLAIMS BY OR AGAINST OTHER CUSTOMERS IN COURT OR IN ARBITRATION OR OTHERWISE PARTICIPATE IN ANY CLAIM AS A CLASS REPRESENTATIVE, CLASS MEMBER OR IN A PRIVATE ATTORNEY GENERAL CAPACITY. The arbitral tribunal may not consolidate more than one person's claims, and may not otherwise preside over any form of a representative or class proceeding. The arbitral tribunal has no power to consider the enforceability of this class arbitration waiver and any challenge to the class arbitration waiver may only be raised in a court of competent jurisdiction.</p>
                  <p>If any provision of this arbitration agreement is found unenforceable, the unenforceable provision will be severed and the remaining arbitration terms will be enforced.</p>
                  <p><strong>Limitation on Time to File Claims</strong></p>
                  <p>ANY ARBITRATION OR CLAIM YOU MAY HAVE ARISING OUT OF OR RELATING TO THESE  TERMS  OF  USE  OR THE  SITE  MUST BE COMMENCED WITHIN ONE (1) YEAR AFTER THE CAUSE OF ACTION ACCRUES; OTHERWISE, SUCH ARBITRATION PROCEEDING, CAUSE OF ACTION, OR CLAIM IS PERMANENTLY BARRED.</p>
                  <p><strong>Indemnification</strong></p>
                  <p>You agree to defend, indemnify, and hold harmless the servicecontract.com its affiliates, subsidiaries, parent, licensors, and service providers, and its and their respective officers, directors, employees, contractors, agents, licensors, suppliers, successors, and assigns from and against any claims, liabilities, damages, judgments, awards, losses, costs, expenses, or fees (including reasonable attorneys' fees) arising out of or relating to your violation of these  Terms  of  Sale or your  use  of the  site, including, but not limited to, your User Contributions, any  use  of the  Site's  content, services, and products other than as expressly authorized in these  Terms  of  Use, or your  use  of any information obtained from the  site.</p>
                  <p><strong>Assignment</strong></p>
                  <p>You will not assign any of your rights or delegate any of your obligations under these Terms without our prior written consent. Any purported assignment or delegation in violation of this section is null and void. No assignment or delegation relieves you of any of your obligations under these Terms.</p>
                  <p><strong>No Waivers</strong></p>
                  <p>The failure by us to enforce any right or provision of these Terms will not constitute a waiver of future enforcement of that right or provision. The waiver of any right or provision will be effective only if in writing and signed by a duly authorized representative of servicecontract.com.</p>
                  <p><strong>No Third-Party Beneficiaries</strong></p>
                  <p>These Terms do not and are not intended to confer any rights or remedies upon any person other than you.</p>
                  <p><strong>Notices</strong></p>
                  <p><strong>To You.</strong> We may provide any notice to you under these Terms by: (i) sending a message to the email address you provide or (ii) by posting to your account or to the site. Notices sent by email will be effective when we send the email and notices we provide by posting will be effective upon posting. It is your responsibility to keep your email address current.</p>
                  <p><strong>To Us.</strong> To give us notice under these Terms, you must contact us as follows: (i) by email to legal@servicecontract.com; or (ii) by personal delivery, overnight courier, or registered or certified mail. We may update the email or address for notices to us by posting a notice on the site. Notices provided by personal delivery will be effective immediately. Notices provided by email or overnight courier will be effective one business day after they are sent. Notices provided by registered or certified mail will be effective three business days after they are sent.</p>
                  <p><strong>Survival</strong></p>
                  <p>The unenforceability, invalidity, or illegality of any provision of these Terms of Sale shall not render the other provisions unenforceable, invalid, or illegal, and the remaining provisions shall remain in full force and effect.</p>
                  <p><strong>Changes to Terms of Use</strong></p>
                  <p id="stacks">Servicecontract.com may revise and update these Terms of Sale, our Terms of Use, site, and our policies from time to time in its sole and absolute discretion. Any revisions are effective immediately for individuals who are not holders of active service contracts and apply to all access to and use of the site thereafter, and effective 30 days from posting for all individuals with an active service contract. Your continued  use  of the site following the posting of revised Terms of Sale means that you accept and agree to the changes. You are expected to check this page from time to time so you are aware of any changes, as they are binding on you.</p>
                  <p><strong>Entire Agreement</strong></p>
                  <p>The  Terms  of Sale, Terms of Use, and our Privacy Notice constitute the sole and entire agreement between you and servicecontract.com regarding the  site  and supersede all prior and contemporaneous understandings, agreements, representations, and warranties, both written and oral, regarding the  site.</p>

                  <p ><strong>Stacks Reward Plan Terms and Conditions </strong></p>
                  <p>You are automatically enrolled in the Reward Plan with your purchase of this Service Contract. Subject to </p>
                  <ul className="newul2">
                  <li><p>Your participation in the Reward Plan for at least thirty-six (36) months through Your successive purchases of Service Contracts for Your Vehicle from Seller and </p></li>
                  <li><p>No claims being filed under said Service Contracts, at the time of Your cancellation of the Service Contract in effect at that time or such other final termination of coverage under the terms of the Service Contract, You may request reimbursement of the applicable reward, which shall be calculated as Your total Monthly Payments paid for the Service Contracts less the aggregate Reward Plan fees under all Service Contracts, administrative fees, and any other applicable fees as permitted by law.</p></li>
                  </ul>
                  <p>Your request for and Our payment of any reward hereunder terminates the Service Contract in effect as of the date of request and requires the recalculation of the Service Contract Price should you purchase a new service contract. You will no longer be eligible to purchase a new service contract with the same coverage, terms, and conditions as this Service Contract by paying the Monthly Payment.</p>
                  

                  <p><strong>Your Questions, Comments, and Concerns</strong></p>
                  <p>This  site  is operated by servicecontract.com, Inc. All notices of copyright infringement claims should be sent to legal@servicecontract.com. All other feedback, comments, requests for technical support, and other communications relating to the  site  should be directed to <a hreft="mailto:help@servicecontract.com">help@servicecontract.com</a>.</p>



              </div>

          </div>
        </section>

        {/* <NewsLatter /> */}
        <div className="break-section">
          <img src={lineImage} alt="line" />
        </div>
        <Footer />
      </Layout>
    );
  }
}


export default LegalStuff
